export const usePortionStore = defineStore('portion', () => {
  const $api = useApi();

  const allPortions = ref([]);
  const activePortion = ref();
  const totalPortions = ref(0);
  const totalPages = ref(1);

  async function fetchAllPortions(data?: Object = {}) {
    const portions = await $api.post(`/portions/all`, data);
    if (portions) {
      allPortions.value = portions.data;
      totalPortions.value = portions.count;
      totalPages.value = portions.totalPages;
    }
  }

  async function fetchDetailedPortion(portionId: string) {
    const { data: portion } = await $api.get(`/portions/${portionId}`);
    activePortion.value = portion;
  }

  async function createPortion(data: Record<string, string>) {
    const portionCreated = await $api.post(`/portions`, data);
    if (portionCreated) {
      allPortions.value.push(portionCreated);
    }
  }

  async function updatePortion(
    portionId: string,
    data: Record<string, string>,
  ) {
    const { data: portionUpdated } = await $api.put(
      `/portions/${portionId}`,
      data,
    );
    if (portionUpdated) {
      const portionToUpdateIndex = allPortions.value.findIndex(
        (n) => n.id === portionId,
      );
      allPortions.value[portionToUpdateIndex] = {
        ...allPortions.value[portionToUpdateIndex],
        ...data,
      };
    }
  }

  return {
    allPortions,
    activePortion,
    totalPortions,
    totalPages,
    fetchAllPortions,
    fetchDetailedPortion,
    updatePortion,
    createPortion,
  };
});
